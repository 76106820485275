<template>
    <div>
        <!-- <img :src="MDlogo" alt=""> -->
        <div style="width:85%;text-align:center;border-radius:5px;margin:10px auto;background:#fff;padding:10px 0">
          <div @click="getTextCode">
             <vue-qr :text="qrCodeText" :size="180" :margin="10" colorDark="#000000" :logoSrc="MDlogo"  :logoScale="0.3" :correctLevel="0" ></vue-qr>
          </div>
           
            <p style="font-size:13px">就诊时出示此二维码</p>
            <p style="font-size:12px; padding:15px;" >(点击二维码可刷新)</p>
        </div>
        <van-button color="#57c4b7" plain size="large" style="width:85%;margin-left:7%;" @click="intoCardbag">加入卡包</van-button>
        <div style="width:85%;margin:0 auto;margin-top:30px;margin-bottom:5px;background:#fff;padding: 0; ">
         
           <van-cell title="姓名：" border style="padding:12px;border-bottom:1px solid #DCDCDC;">
              
                   <span style="color:black">{{userName}}</span>
               
           </van-cell>
           <van-cell title="身份证：" border  style="padding:12px;border-bottom:1px solid #DCDCDC;">
              
                   <span style="color:black">{{idCard.substr(0,4)+'*********'+idCard.substr(14)}}</span>
               
           </van-cell>
           <van-cell title="电话号码：" border  style="padding:12px;">
              
                   <span style="color:black">{{phone.substr(0,4)+'*********'+phone.substr(7)}}</span>
               
           </van-cell>
           
        </div>
         <div @click="gotoUpData" style="text-decoration:underline;padding:5px;padding-right:30px;font-size:14px;text-align:right;color:#57c4b7">更改绑定证件</div>
        <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 30px;
        text-align:center
      "
    >
      <span>&copy;拓实银医</span>
    </div>
      <div style="position:fixed;bottom:0;left:0;right:0;height:40px;">
         <van-button color="#FF7F50" style=" position:relative;left:0;top:0;bottom:0;width:50%;" @click="onclickdel">解除绑定</van-button>
          <van-button color="#57c4b7" style=" position:relative;right:0;top:0;bottom:0;width:50%;" @click="onclickimport" >设为默认主卡</van-button>
      </div>

    </div>
</template>
<script>
import store from "../../store";
import ajax from "../../lib/ajax";
import { Dialog,Toast } from "vant";
export default {
    data(){
        return{
          HealthCode:"",
          openid:"", 
          userName:"",
          idCard:"",
          phone:"",
          qrCodeText:"",
          MDlogo:require("../../assets/imagel/logo_.png"),
          card: {},
          healthCardId:"",
          // color:"",
          // colorText:""
        }
    }
    ,mounted(){
            this.userName = store.getters["store/userName"];
            this.idCard=store.getters["store/idcard"];
            this.phone=store.getters["store/phone"];
            this.healthCardId=store.getters["store/healthCardId"];
            this.maincard=store.getters["store/owneridcard"];
            console.log("ssss")
            console.log(this.maincard)
           this.getTextCode();
          
    },
    methods:{
      // 已经注册过直接跳转才会触发
      getTextCode(){
        // console.log(this.healthCardId)
            ajax.get("/Api/HealthCardService/GetHealthQrCode?cardid="+this.healthCardId+"&idcard="+this.idCard+"&codetype=0")
                    .then((res)=>{
                      // if(res.data.result.rsp.color==0){
                      //   this.color="#000000"
                      //   this.colorText="未申报"
                      // }else if(res.data.result.rsp.color==1){
                      //   this.color="#006400"
                      //   this.colorText="低风险"
                      // }else if(res.data.result.rsp.color==2){
                      //   this.color="#FF8C00"
                      //   this.colorText="中风险"
                      // }else if(res.data.result.rsp.color==3){
                      //   this.color="#FF0000"
                      //   this.colorText="高风险"
                      // }else{
                      //    this.color="#006400"
                      //     this.colorText="低风险"
                      // }
                        
                        this.qrCodeText=res.data.result.rsp.qrCodeText;
                      //  console.log(res);
                        })
                      
    },
    // 删除卡
    onclickdel(){
        // let mainid;
        // mainid = this.maincard;
        if (this.idCard==this.maincard) {
          Dialog.alert({
            title:'温馨提示',
            message:'主卡暂时不可解绑',
          });
        }else {
          Dialog.confirm({
            title:'温馨提示',
            message:'确认解绑吗？'
          }).then(() => {
            ajax.get("/Api/CardService/WeChatDeleteMember?input="+this.idCard
            // , {
            //   ownerIDCard: mainid,
            //   deleteMemberIDCard: this.idCard,
            // }
            ).then((res) => {
              console.log(res);
              Toast("解绑成功");
              this.$router.replace("/healcard").catch((err)=>{console.log(err)});
            }).catch((err) => {
              console.log(err)
              Toast("解绑失败，请稍后再试...")
            })
          }).catch(() => {
             Toast("您已取消操作")
          });
        }
    },
    // 设置主卡
    onclickimport(){
        let mainid;
        mainid = this.maincard;
        if (this.idCard==mainid) {
          Dialog.alert({
            title:'温馨提示',
            message:'该卡已是主卡',
          });
        } else {
          Dialog.confirm({
            title:'温馨提示',
            message:'确认修改主卡'
          }).then(() => {
            ajax.get("/Api/CardService/WeChatChangeOwner?oldNo="+mainid+"&newNo="+this.idCard)
            .then((res) => {
              // console.log("你好")
              console.log(res);
              Toast("更换主卡成功");
               this.$router.replace("/healcard").catch((err)=>{console.log(err)})
            }).catch(() => {
              Toast("更换失败，请稍后再试...")
            })
          }).catch(() => {
             Toast("您已取消操作！")
          });
        }
      },
      //加入卡包
    intoCardbag(){
      if(this.qrCodeText==""){
         Toast("数据更新失败，请刷新页面后重试");
         return;
      }
      
         ajax.get(`/Api/HealthCardService/GetOrderId?qrcode=${this.qrCodeText}`)
         .then((res)=>{
           if(res.data.result.commonOut.errMsg=="成功"){
            let orderId=res.data.result.rsp.orderId;
                let redirect_uri="http://wx.fgxrmyy.cn/HcCode";
     window.location.href=`https://health.tengmed.com/open/takeMsCard?order_id=${orderId}&redirect_uri=${redirect_uri}`;

           }else{
             
             Toast(res.data.result.commonOut.errMsg);
           }
           console.log(res.data)

         })
         .catch((err)=>{
           console.log(err);
           Toast("数据更新失败，请刷新页面后重试");
         })
    },
    // 修改信息页面
    gotoUpData(){
      //  store.commit('store/setQrCodeText',this.qrCodeText);
    
       sessionStorage.setItem('upqrCodeText',this.qrCodeText); 
       sessionStorage.setItem('upidCard',this.idCard); 
      this.$router.replace("uinfo").catch((err)=>{console.log(err)})
    }
    }
}
</script>
<style lang="">
</style>